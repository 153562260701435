<template>
  <div>
    <title-bar :title="$t('navigations.baskets')"/>
    <div v-if="orders" class="custom-table">
      <table-actions
        :actions="['perPage']"
        @perPageChange="perPageChange"
        :defaultPerPage="perPage"
      />
      <table-lite
        :is-slot-mode="true"
        :is-loading="isLoading"
        :columns="columns"
        :rows="orders"
        :sortable="{}"
        @is-finished="isLoading = false"
      >
        <template v-slot:id="data">
          <router-link :to="$helper.getEditUrl('basket', data.value.id)">{{ data.value.id }}</router-link>
        </template>
        <template v-slot:user="data">
          {{ data.value.client.email }}
        </template>
        <template v-slot:totalPrice="data">
          {{ $helper.priceFormat(data.value.totalPrice) }}
        </template>
        <template v-slot:actions="data">
          <div class="just-buttons">
            <edit-btn :path="$helper.getEditUrl('basket', data.value.id)"/>
            <delete-btn @pressDelete="deleteOrder(data.value['@id'])"/>
          </div>
        </template>
      </table-lite>
      <table-pagination
        v-if="pages"
        v-model="currentPage"
        :total="pages"
        :per-page="perPage"
        @input="onChangePage"
      />
    </div>
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
  components: {
    TableLite,
    TablePagination,
    TableActions,
    EditBtn,
    DeleteBtn,
    TitleBar
  },
  mixins: [ResourceUtils],
  data() {
    return {
      isLoading: false,
      orders: null,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
      pages: 0,
      columns: [
        {
          label: this.$t('order.orderId'),
          field: "id",
          sortable: false,
        },
        {
          label: this.$t('order.user'),
          field: "user",
          sortable: false,
        },
        {
          label: this.$t('order.total'),
          field: "totalPrice",
          sortable: false,
        },
        {
          label: this.$t('order.actions'),
          field: "actions",
          sortable: false,
          width: "120px",
        },
      ],
    };
  },
  created() {
    this.loadOrders();
  },
  methods: {
    loadOrders() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
        'order[createdAt]': 'desc',
        status: ['DRAFT']
      };
      this.$Orders.getCollection({params}, 'basket_list').then((response) => {
        this.pages = response.data["hydra:totalItems"];
        this.orders = response.data["hydra:member"];
      });
    },
    onChangePage(page) {
      this.currentPage = page;
      this.loadOrders();
    },
    deleteOrder(url) {
      this.deleteByUrl(this.$Orders, url, this.$t('order.deleted'), null, this.loadOrders, this.error);
    },
    error() {
      this.$emit("clearAction");
    },
    onPageChange() {
      this.loadOrders();
    },
    perPageChange(num) {
      this.currentPage = 1;
      this.perPage = num;
      this.loadOrders();
    },
  },
};
</script>
